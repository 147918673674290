/**
 * @license
 * Copyright 2021 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */

import * as params from './params';

export function dist(x1, y1, x2, y2) {
    const x = x2 - x1;
    const y = y2 - y1;
    const distance = Math.sqrt(x * x + y * y);
    return distance;
}

export function angle(p1x, p1y, p2x, p2y) {
    const angleRad = Math.atan2(p2y - p1y, p2x - p1x);
    return rad2deg(angleRad);
}

export function rad2deg(rad) {
    let result = rad * (180 / Math.PI) + 180;
    if (result > 360) {
        result = result - 360;
    }
    return result;
}

// Gets the angle (in radians) between three points
// vertex: Vertex point
// a: Point A
// b: Point B
// Returns: Angle in radians
export function getAngleBetweenThreePoints(vertex, a, b)
{
    const ab = getDistanceBetweenPoints(a, b);
    const va = getDistanceBetweenPoints(vertex, a);
    const vb = getDistanceBetweenPoints(vertex, b);

    const angle = Math.acos((Math.pow(va, 2) + Math.pow(vb, 2) - Math.pow(ab, 2)) / (2 * va * vb));
    return angle;
}

// Get distance (pixels) between two points
// returns distance (Pixels)
export function getDistanceBetweenPoints(a, b) {
    const bxMax = b.x - a.x;
    const byMay = b.y - a.y;
    const sumaDeCuadrados = Math.pow(bxMax, 2) + Math.pow(byMay, 2);
    const raiz = Math.sqrt(sumaDeCuadrados);
    const distance = parseInt(Math.trunc(raiz));
    return distance;
}