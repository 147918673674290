/**
 * @license
 * Copyright 2021 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */

/* INFO: Points top left is 1000 x and 0 Y. The 0,0 is the top right. Camera is fliped. Btm is > 1000*/

import * as params from './params';
import * as mathUtils from './math_utils';
const errorsAnalysis = require('./error_detection');



//SWING FLAGS
let inPosition = false;
const thresholdStartPoint = 0.75;

let swingStarted = false;
let holdSwingStartPositionCount = 0;

let swingInProgress = false;
let swingStartSide = "";

let endIsNear = false;
let swingEnded = false;

let waitForSwingEndPositionCount = 0;
let waitPostSwingError = 0;
const expectedWaitPostSwingError = 40;

let topDetectedOpt = false;
let impactDetectedOpt = false;

let swingPosition = params.SWING_POSITION.NOT_STARTED;

let currentFrame = 0;
//LOGGERS
let enableLoggers = false;
let contLogger = 0;
const contLoggerExpected = 150;

var lastErrorMessage = "";
var lastStatusMessage = "";

function setErrorMessage(msg){

    if (lastErrorMessage == msg) { return; }
    lastErrorMessage = msg;

    const errorMessage = document.getElementById('error');
    if (errorMessage) { errorMessage.innerHTML = msg; }
    parent.postMessage({msgType: "errorMessage", msgBody: msg},"*"); 
}

function setStatusMessage(msg){

    if (lastStatusMessage == msg) { return; }
    lastStatusMessage = msg;

    const statusMessage = document.getElementById('status');
    if (statusMessage) { statusMessage.innerHTML = msg; }
    parent.postMessage({msgType: "statusMessage", msgBody: msg},"*"); 
}

function restartSwingDetection() {
    inPosition = false;
    swingStarted = false;
    holdSwingStartPositionCount = 0;
    setStatusMessage("GET IN POSITION");
    setErrorMessage("");
    swingEnded = false;
    waitForSwingEndPositionCount = 0;
    swingStartSide = "";
    swingInProgress = false;
    endIsNear = false;
    swingPosition = params.SWING_POSITION.NOT_STARTED;
    waitPostSwingError = 0;
    currentFrame = 0;
    topDetectedOpt = false;
    impactDetectedOpt = false;
}

function poseDetection(keypoints) {
    //Analyze errors
    if (swingStarted && waitPostSwingError <= expectedWaitPostSwingError) {
        errorsAnalysis(keypoints, swingPosition, currentFrame);
    }
    // Detect User is in view
    if (!inPosition) {
        validatePositionUser(keypoints);
        return;
    }
    // Detect start of Swing
    if (inPosition && !swingStarted) {
        detectSwingStart(keypoints);
        return;
    }

    currentFrame++;

    //Detect position
    if (swingStarted && !swingInProgress) {
        detectSwingSide(keypoints);
        return;
    }

    //Detection of keyPoints that are not required workflow
    if (swingInProgress && !endIsNear) {
        if (!topDetectedOpt) {
            detectOptionalSwingTop(keypoints);
        } else if (!impactDetectedOpt) {
            detectOptionalSwingImpact(keypoints);
        } else {
            swingPosition = params.SWING_POSITION.SWING;
        }
    }

    // Detect near end of Swing
    if (swingInProgress && !endIsNear) {
        detectSwingEndIsNear(keypoints);
        return;
    }

    // Detect end of Swing
    if (endIsNear && !swingEnded) {
        detectSwingEnd();
        return;
    }

    if (swingEnded && waitPostSwingError <= expectedWaitPostSwingError) {
        waitPostSwingError++;
        if (waitPostSwingError == expectedWaitPostSwingError) {
            swingPosition = params.SWING_POSITION.ANALYSIS;
        }
    }
}

function validatePositionUser(keypoints) {
    const headX = keypoints[params.KEYPOINTS.NOSE].x;
    const headY = keypoints[params.KEYPOINTS.NOSE].y;
    const rLegX = keypoints[params.KEYPOINTS.RIGHT_ANKLE].x;
    const rLegY = keypoints[params.KEYPOINTS.RIGHT_ANKLE].y;
    const lLegX = keypoints[params.KEYPOINTS.LEFT_ANKLE].x;
    const lLegY = keypoints[params.KEYPOINTS.LEFT_ANKLE].y;
    const rhipX = keypoints[params.KEYPOINTS.RIGHT_HIP].x;
    const lhipX = keypoints[params.KEYPOINTS.LEFT_HIP].x;

    const rLegScore = keypoints[params.KEYPOINTS.RIGHT_ANKLE].score;
    const lLegScore = keypoints[params.KEYPOINTS.LEFT_ANKLE].score;
    const headScore = keypoints[params.KEYPOINTS.NOSE].score;
    const rHandScore = keypoints[params.KEYPOINTS.RIGHT_WRIST].score;
    const lHandScore = keypoints[params.KEYPOINTS.LEFT_WRIST].score;
    const rHipScore = keypoints[params.KEYPOINTS.RIGHT_HIP].score;
    const lHipScore = keypoints[params.KEYPOINTS.LEFT_HIP].score;
    const verifyBodyInFrame = rLegScore > thresholdStartPoint && lLegScore > thresholdStartPoint && headScore > thresholdStartPoint && rHandScore > thresholdStartPoint && lHandScore > thresholdStartPoint && rHipScore > thresholdStartPoint && lHipScore > thresholdStartPoint;

    const minHip = Math.min(lhipX, rhipX);
    const maxHip = Math.max(lhipX, rhipX);
    const headOk = headX >= minHip && headX <= maxHip && headY > 30;
    const tooClose = Math.max(lLegY - headY, rLegY - headY) > 900;

    if (!verifyBodyInFrame) {
        setErrorMessage("Get body inside frame");
    } else if (tooClose) {
        setErrorMessage("Step further away to the camera");
    } else if (headOk) {
        inPosition = true;
        setStatusMessage("GET IN STARTING POSITION");
        setErrorMessage("");;
    }

    //LOGGER - START
    contLogger = contLogger + 1;
    if (enableLoggers && (contLogger == contLoggerExpected || fullBodyOk)) {
        contLogger = 0;
        console.log(`HeadX: ${headX}, HeadY: ${headY}, HeadOk: ${headOk}, Score: ${headScore}`);
        console.log(`rLegX: ${rLegX}, rLegY: ${rLegY}, rlegOk: ${rlegOk}, Score: ${rLegScore}`);
        console.log(`lLegX: ${lLegX}, leftLegY: ${lLegY}, llegOk: ${llegOk}, Score: ${lLegScore}`);
        console.log(`tooClose: ${tooClose}, headOk: ${headOk}, verifyBodyInFrame: ${verifyBodyInFrame}`);
    }
    //LOGGER - END
}
function detectSwingStart(keypoints) {
    const lWristX = keypoints[params.KEYPOINTS.LEFT_WRIST].x;
    const lWristY = keypoints[params.KEYPOINTS.LEFT_WRIST].y;
    const rWristX = keypoints[params.KEYPOINTS.RIGHT_WRIST].x;
    const lShoulderX = keypoints[params.KEYPOINTS.LEFT_SHOULDER].x;
    const lShoulderY = keypoints[params.KEYPOINTS.LEFT_SHOULDER].y;
    const rShoulderX = keypoints[params.KEYPOINTS.RIGHT_SHOULDER].x;
    const lHipY = keypoints[params.KEYPOINTS.LEFT_HIP].y;

    const lAnkleScore = keypoints[params.KEYPOINTS.LEFT_ANKLE].score;
    const rAnkleScore = keypoints[params.KEYPOINTS.RIGHT_ANKLE].score;
    const validateScoring = lAnkleScore > thresholdStartPoint && rAnkleScore > thresholdStartPoint; 
    if (!validateScoring) {
        setErrorMessage("Get back into frame.");
        holdSwingStartPositionCount = 0;
        inPosition = false;
        setStatusMessage("GET IN POSITION");
    }

    const distWrists = Math.abs(lWristX - rWristX);
    const wristsAreClose = distWrists < 150;

    const inHorizontalPosition = (lWristX >= rShoulderX) && (lWristX <= lShoulderX);

    const inVerticalPosition = lWristY >= ((lShoulderY + lHipY) / 2);

    if (wristsAreClose && inHorizontalPosition && inVerticalPosition) {
        holdSwingStartPositionCount++;
    } else {
        holdSwingStartPositionCount = 0;
    }

    let swingStartCondition = holdSwingStartPositionCount > 30;
    if (swingStartCondition) {
        swingStarted = true;
        setStatusMessage("SWING!");
        swingPosition = params.SWING_POSITION.SET_UP;
    }

    //LOGGER - START
    contLogger = contLogger + 1;
    if (enableLoggers && (contLogger == contLoggerExpected || swingStartCondition)) {
        contLogger = 0;
        console.log(`distWrists: ${distWrists}, wristsAreClose: ${wristsAreClose}`);
        console.log(`lWristX: ${lWristX}, rShoulderX: ${rShoulderX}, lShoulderX: ${lShoulderX}, inHorizontalPosition: ${inHorizontalPosition}`);
        console.log(`lShoulderY: ${lShoulderY}, lHipY: ${lHipY}, lWristY: ${lWristY}, inVerticalPosition: ${inVerticalPosition}`);
    }
    //LOGGER - END
}

function detectSwingSide(keypoints) {

    const lWristX = keypoints[params.KEYPOINTS.LEFT_WRIST].x;
    const lWristY = keypoints[params.KEYPOINTS.LEFT_WRIST].y;
    const lShoulderX = keypoints[params.KEYPOINTS.LEFT_SHOULDER].x;
    const lShoulderY = keypoints[params.KEYPOINTS.LEFT_SHOULDER].y;
    const rShoulderX = keypoints[params.KEYPOINTS.RIGHT_SHOULDER].x;
    const rShoulderY = keypoints[params.KEYPOINTS.RIGHT_SHOULDER].y;

    const middlePointX = (lShoulderX + rShoulderX) / 2;
    const middlePointY = (lShoulderY + rShoulderY) / 2;
    const angleMidPointWrist = mathUtils.angle(middlePointX, middlePointY, lWristX, lWristY);

    const swingInProgressCondition = !(210 <= angleMidPointWrist && angleMidPointWrist <= 330);
    
    if (swingInProgressCondition) {
        swingInProgress = true;
        swingStartSide = (90 <= angleMidPointWrist && angleMidPointWrist <= 210) ? "left" : "right";
        swingPosition = params.SWING_POSITION.SWING;
    }

    //LOGGER - START
    contLogger = contLogger + 1;
    if (enableLoggers && (contLogger == contLoggerExpected || swingInProgressCondition)) {
        contLogger = 0;
        console.log(`angleMidPointWrist: ${angleMidPointWrist}, swingInProgressCondition: ${swingInProgressCondition}`);
        console.log(`swingStartSide: ${swingStartSide}`);
    }
    //LOGGER - END
}
function detectOptionalSwingTop(keypoints) {
    const lWristX = keypoints[params.KEYPOINTS.LEFT_WRIST].x;
    const lWristY = keypoints[params.KEYPOINTS.LEFT_WRIST].y;

    const lShoulderX = keypoints[params.KEYPOINTS.LEFT_SHOULDER].x;
    const lShoulderY = keypoints[params.KEYPOINTS.LEFT_SHOULDER].y;
    const rShoulderX = keypoints[params.KEYPOINTS.RIGHT_SHOULDER].x;
    const rShoulderY = keypoints[params.KEYPOINTS.RIGHT_SHOULDER].y;

    const middlePointX = (lShoulderX + rShoulderX) / 2;
    const middlePointY = (lShoulderY + rShoulderY) / 2;

    const angleMidPointWrist = mathUtils.angle(middlePointX, middlePointY, lWristX, lWristY);

    const userIsInTopPosition = (angleMidPointWrist < 140 && angleMidPointWrist > 40);

    if (userIsInTopPosition) {
        swingPosition = params.SWING_POSITION.TOP;
    } else if (swingPosition == params.SWING_POSITION.TOP) {
        topDetectedOpt = true;
        swingPosition = params.SWING_POSITION.SWING;
    }

    //LOGGER - START
    contLogger = contLogger + 1;
    if (enableLoggers && (contLogger == contLoggerExpected || swingPosition == params.SWING_POSITION.TOP)) {
        contLogger = 0;
        console.log(`angleMidPointWrist: ${angleMidPointWrist}, userIsInTopPosition: ${userIsInTopPosition}`);
    }
    //LOGGER - END
}
function detectOptionalSwingImpact(keypoints) {
    const lWristX = keypoints[params.KEYPOINTS.LEFT_WRIST].x;
    const lWristY = keypoints[params.KEYPOINTS.LEFT_WRIST].y;

    const lShoulderX = keypoints[params.KEYPOINTS.LEFT_SHOULDER].x;
    const lShoulderY = keypoints[params.KEYPOINTS.LEFT_SHOULDER].y;
    const rShoulderX = keypoints[params.KEYPOINTS.RIGHT_SHOULDER].x;
    const rShoulderY = keypoints[params.KEYPOINTS.RIGHT_SHOULDER].y;

    const middlePointX = (lShoulderX + rShoulderX) / 2;
    const middlePointY = (lShoulderY + rShoulderY) / 2;

    const angleMidPointWrist = mathUtils.angle(middlePointX, middlePointY, lWristX, lWristY);

    const userIsInImpactPosition = (angleMidPointWrist < 290 && angleMidPointWrist > 250);

    if (userIsInImpactPosition) {
        swingPosition = params.SWING_POSITION.IMPACT;
    } else if (swingPosition == params.SWING_POSITION.IMPACT) {
        impactDetectedOpt = true;
        swingPosition = params.SWING_POSITION.SWING;
    }

    //LOGGER - START
    contLogger = contLogger + 1;
    if (enableLoggers && (contLogger == contLoggerExpected || swingPosition == params.SWING_POSITION.IMPACT)) {
        contLogger = 0;
        console.log(`angleMidPointWrist: ${angleMidPointWrist}, userIsInImpactPosition: ${userIsInImpactPosition}`);
    }
    //LOGGER - END
}
function detectSwingEndIsNear(keypoints) {

    const lWristX = keypoints[params.KEYPOINTS.LEFT_WRIST].x;
    const lWristY = keypoints[params.KEYPOINTS.LEFT_WRIST].y;

    const lShoulderX = keypoints[params.KEYPOINTS.LEFT_SHOULDER].x;
    const lShoulderY = keypoints[params.KEYPOINTS.LEFT_SHOULDER].y;
    const rShoulderX = keypoints[params.KEYPOINTS.RIGHT_SHOULDER].x;
    const rShoulderY = keypoints[params.KEYPOINTS.RIGHT_SHOULDER].y;

    const middlePointX = (lShoulderX + rShoulderX) / 2;
    const middlePointY = (lShoulderY + rShoulderY) / 2;

    const angleMidPointWrist = mathUtils.angle(middlePointX, middlePointY, lWristX, lWristY);

    if (swingStartSide === "left") {
        endIsNear = (330 <= angleMidPointWrist || angleMidPointWrist <= 90);
    }
    if (swingStartSide === "right") {
        endIsNear = (90 <= angleMidPointWrist && angleMidPointWrist <= 210);
    }

    //LOGGER - START
    contLogger = contLogger + 1;
    if (enableLoggers && (contLogger == contLoggerExpected || endIsNear)) {
        contLogger = 0;
        console.log(`angleMidPointWrist: ${angleMidPointWrist}`);
        console.log(`endIsNear: ${endIsNear}`);
    }
    //LOGGER - END
}

function detectSwingEnd() {

    waitForSwingEndPositionCount = waitForSwingEndPositionCount + 1;

    //100 is close to 1.6 secs. iOS uses 0.75
    let swingEndCondition = waitForSwingEndPositionCount > 60;
    if (swingEndCondition) {
        swingEnded = true;
        setStatusMessage("PERFECT!");
        swingPosition = params.SWING_POSITION.POST_FINISH;
    }

    //LOGGER - START
    if (enableLoggers && swingEnded) {
        console.log(`swingEnded: ${swingEnded}`);
    }
    //LOGGER - END
}

module.exports = poseDetection;