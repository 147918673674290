/**
 * @license
 * Copyright 2021 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */

import * as params from './params';
import * as mathUtils from './math_utils';
import { isMobile } from './util';

let toleranceSmall = 0;
let toleranceNormal = 0;
let toleranceBig = 0;
let swingCurrentPosition = "";
const thresholdPoint = 0.4;//MlKit ios app

let swingFrame = 0;

let currentFrameAnalysisGap = 0;
const expectedGapOfFramesAnalysis = 4;

// Starting Points
let startAnalysis = true;
const expectedStartingPointsSaveCount = 5;

let rShoulderFirstKf = null;
let lShoulderFirstKf = null;
let rAnkleFirstKf = null;
let lAnkleFirstKf = null;

//Errors result
let swingErrors = [];
let errorType = '';
let errorDescription = '';
let errorArmTopCounter = 0;

//LOGGERS
let enableErrorLoggers = false;
let contErrorLogger = 0;
const contErrorLoggerExpected = 150;

var lastErrorMessage = "";

function setErrorMessage(msg){

    if (lastErrorMessage == msg) { return; }
    lastErrorMessage = msg;

    const errorMessage = document.getElementById('error');
    if (errorMessage) { errorMessage.innerHTML = msg; }
    parent.postMessage({msgType: "errorMessage", msgBody: msg},"*"); 
}
 
function restartErrorDetection() {
    setErrorMessage("");
    swingResultsTextArea.value = "";
    toleranceNormal = 0;
    toleranceBig = 0;
    toleranceSmall = 0;
    startAnalysis = true;
    rShoulderFirstKf = null;
    lShoulderFirstKf = null;
    rAnkleFirstKf = null;
    lAnkleFirstKf = null;
    currentFrameAnalysisGap = 0;
    swingFrame = 0;
    swingErrors = [];
    errorArmTopCounter = 0;
}

function errorsAnalysis(keypoints, swingPosition, currentFrame) {
    swingCurrentPosition = swingPosition;
    swingFrame = currentFrame;
    currentFrameAnalysisGap++;

    // DETECT END OF SWING
    if (swingCurrentPosition == params.SWING_POSITION.ANALYSIS) {
        console.log("****Analysis Ended****");
        const errorsJson = JSON.stringify(swingErrors);
        console.log(errorsJson);
        parent.postMessage({msgType: "detectedErrors", msgBody: swingErrors},"*"); 
        if (isMobile()) {
            swingResultsTextArea.hidden = false;
            swingResultsTextArea.value = errorsJson;
        }        
        return;
    }

    // PREPARE FOR ANALYSIS
    if (startAnalysis) {
        if (currentFrameAnalysisGap < expectedStartingPointsSaveCount) {
            return;
        }
        saveStartingPoints(keypoints);
        startAnalysis = false;
    }
    const isInFocusSwingKf = (swingCurrentPosition == params.SWING_POSITION.TOP || swingCurrentPosition == params.SWING_POSITION.IMPACT);
    const isLessThanExpectedFrameGap = currentFrameAnalysisGap < expectedGapOfFramesAnalysis;
    if (isLessThanExpectedFrameGap && !isInFocusSwingKf) {
        return;
    }
    currentFrameAnalysisGap = 0;

    // ANALYZE FOR ERRORS IN FRAME
    checkTriangle(keypoints);
    checkRectangle(keypoints);
    checkCircle(keypoints);
    checkLine(keypoints);
    checkTopSwing(keypoints);

    if (enableErrorLoggers) {
        restartErrorBtn.hidden = false;
    }
}
function recordError(errorType, keyPoint, description, swingPoint) {
    const error = {
        swingFrame,
        errorType,
        keyPoint,
        description,
        swingPoint
    };

    swingErrors.push(error);

    if (enableErrorLoggers) {
        console.log(`swingFrame: ${swingFrame}, errorType: ${errorType}, keyPoint: ${keyPoint}, description: ${description}, swingPoint: ${swingPoint}`);
    }
}

function saveStartingPoints(keypoints) {
    const uow = (Math.abs(keypoints[params.KEYPOINTS.RIGHT_EAR].x - keypoints[params.KEYPOINTS.LEFT_EAR].x));
    toleranceNormal = 0.5 * uow;
    toleranceBig = uow * 2;
    toleranceSmall = uow * 0.25;

    rShoulderFirstKf = keypoints[params.KEYPOINTS.RIGHT_SHOULDER];
    lShoulderFirstKf = keypoints[params.KEYPOINTS.LEFT_SHOULDER];
    rAnkleFirstKf = keypoints[params.KEYPOINTS.RIGHT_ANKLE];
    lAnkleFirstKf = keypoints[params.KEYPOINTS.LEFT_ANKLE];

    //LOGGER - START
    if (enableErrorLoggers) {
        console.log("****Starting Points****");
        console.log(`toleranceNormal: ${toleranceNormal}`);
        console.log(`rShoulderFirstKfX: ${rShoulderFirstKf.x}, lShoulderFirstKfX: ${lShoulderFirstKf.x}`);
        console.log(`rAnkleFirstKf: ${rAnkleFirstKf.x}, lAnkleFirstKf: ${lAnkleFirstKf.x}`);
    }
    //LOGGER - END
}
function checkRectangle(keypoints) {
    const rAnkleX = keypoints[params.KEYPOINTS.RIGHT_ANKLE].x;
    const rAnkleScore = keypoints[params.KEYPOINTS.RIGHT_ANKLE].score;
    const lAnkleX = keypoints[params.KEYPOINTS.LEFT_ANKLE].x;
    const lAnkleScore = keypoints[params.KEYPOINTS.LEFT_ANKLE].score;

    const rHipX = keypoints[params.KEYPOINTS.RIGHT_HIP].x;
    const lHipX = keypoints[params.KEYPOINTS.LEFT_HIP].x;

    const rKneeX = keypoints[params.KEYPOINTS.RIGHT_KNEE].x;
    const lKneeX = keypoints[params.KEYPOINTS.LEFT_KNEE].x;

    const rKneeScore = keypoints[params.KEYPOINTS.RIGHT_KNEE].score;
    const lKneeScore = keypoints[params.KEYPOINTS.LEFT_KNEE].score;

    errorType = 'rectangle';

    //RHip never to the Left of my LShoulder
    //LHip never to the Right of my RShoulder
    if ((rHipX - toleranceSmall) > lShoulderFirstKf.x || (lHipX + toleranceSmall) < rShoulderFirstKf.x) {
        errorDescription = 'Hips outside opposite limit';
        setErrorMessage(errorDescription);
        recordError(errorType, params.SWING_POINT.HIP, errorDescription, swingCurrentPosition);
    }

    //RKnee never to the Left of my LShoulder
    //LKnee never to the Right of my RShoulder
    if ((rKneeX - toleranceSmall) > lShoulderFirstKf.x || (lKneeX + toleranceSmall) < rShoulderFirstKf.x) {
        errorDescription = 'Knee outside opposite limit';
        setErrorMessage(errorDescription);
        recordError(errorType, params.SWING_POINT.KNEE, errorDescription, swingCurrentPosition);
    }

    //Knees can reach the limit but not move away from it
    if ((rKneeScore > thresholdPoint && (rKneeX + toleranceNormal) < rShoulderFirstKf.x) || (lKneeScore > thresholdPoint && (lKneeX - toleranceNormal) > lShoulderFirstKf.x)) {
        errorDescription = 'Knee outside same side limit';
        setErrorMessage(errorDescription);
        recordError(errorType, params.SWING_POINT.KNEE, errorDescription, swingCurrentPosition);
    }

    //The ankles should not move in X
    const isInCorrectSwingForAnkleCheckKf = (swingCurrentPosition != params.SWING_POSITION.POST_FINISH && swingCurrentPosition != params.SWING_POSITION.SET_UP);
    const ankleScore = rAnkleScore > thresholdPoint && lAnkleScore > thresholdPoint;
    if (isInCorrectSwingForAnkleCheckKf && ankleScore && ((rAnkleX + toleranceBig) < rAnkleFirstKf.x || (rAnkleX - toleranceBig) > rAnkleFirstKf.x) || ((lAnkleX + toleranceBig) < lAnkleFirstKf.x || (lAnkleX - toleranceBig) > lAnkleFirstKf.x)) {
        errorDescription = 'Ankles had stray from starting position';
        setErrorMessage(errorDescription);
        recordError(errorType, params.SWING_POINT.ANKLE, errorDescription, swingCurrentPosition);
    }

    //LOGGER - START
    contErrorLogger++;
    if (enableErrorLoggers && contErrorLogger == contErrorLoggerExpected) {
        contErrorLogger = 0;
        console.log(`rHipX: ${rHipX}, lHipX: ${lHipX}`);
        console.log(`rKneeX: ${rKneeX}, rKneeX: ${rKneeX}`);
        console.log(`rAnkleX: ${rAnkleX}, lAnkleX: ${lAnkleX}`);
    }
    //LOGGER - END
}

function checkCircle(keypoints) {
    const rShoulderX = keypoints[params.KEYPOINTS.RIGHT_SHOULDER].x;
    const lShoulderX = keypoints[params.KEYPOINTS.LEFT_SHOULDER].x;
    const headMiddle = keypoints[params.KEYPOINTS.NOSE].x;

    errorType = 'circle';

    const shoulderMiddlePointX = (lShoulderX + rShoulderX) / 2;
    const isInCorrectSwingKf = (swingCurrentPosition != params.SWING_POSITION.POST_FINISH && swingCurrentPosition != params.SWING_POSITION.SET_UP);

    if (isInCorrectSwingKf && (((headMiddle - toleranceNormal) > shoulderMiddlePointX) || ((headMiddle + toleranceNormal) < shoulderMiddlePointX))) {
        errorDescription = 'Head position outside limits';
        setErrorMessage(errorDescription);
        recordError(errorType, params.SWING_POINT.HEAD, errorDescription, swingCurrentPosition);
    }
}

function checkTriangle(keyPoints) {
    if (swingCurrentPosition != params.SWING_POSITION.SET_UP) {
        return;
    }

    const rShoulderY = keyPoints[params.KEYPOINTS.RIGHT_SHOULDER].y;
    const lShoulderY = keyPoints[params.KEYPOINTS.LEFT_SHOULDER].y;
    const headMiddleY = keyPoints[params.KEYPOINTS.NOSE].y;
    
    errorType = "Triangle";

    if (rShoulderY <= (headMiddleY - toleranceSmall) || lShoulderY <= (headMiddleY - toleranceSmall)) {
        errorDescription = 'Triangle measures shoulder tilt and arms';
        setErrorMessage(errorDescription);
        recordError(errorType, params.SWING_POINT.SHOULDER, errorDescription, swingCurrentPosition);
    }
}

function checkLine(keypoints) {
    const rAnkleX = keypoints[params.KEYPOINTS.RIGHT_ANKLE].x;
    const lAnkleX = keypoints[params.KEYPOINTS.LEFT_ANKLE].x;

    const rKneeX = keypoints[params.KEYPOINTS.RIGHT_KNEE].x;
    const lKneeX = keypoints[params.KEYPOINTS.LEFT_KNEE].x;

    const rHipX = keypoints[params.KEYPOINTS.RIGHT_HIP].x;
    const lHipX = keypoints[params.KEYPOINTS.LEFT_HIP].x;

    errorType = "Line";

    const isSetUp = swingCurrentPosition == params.SWING_POSITION.SET_UP;
    if (isSetUp && (rKneeX < rAnkleX || lKneeX > lAnkleX)) {
        errorDescription = 'Knee position is outside barrel';
        setErrorMessage(errorDescription);      
        recordError(errorType, params.SWING_POINT.KNEE, errorDescription, swingCurrentPosition);
    }

    const isPostFinish = swingCurrentPosition == params.SWING_POSITION.POST_FINISH;
    const lSwingKnee = lKneeX > lAnkleX;
    const lHipAnklePosition = (Math.abs(lKneeX - lHipX) > Math.abs(lAnkleX - lKneeX));
    const rSwingKnee = rKneeX < rAnkleX;
    const rHipAnklePosition = (Math.abs(rHipX - rKneeX) > Math.abs(rKneeX - rAnkleX));

    if (isPostFinish && ((lSwingKnee && lHipAnklePosition) || (rSwingKnee && rHipAnklePosition)))
    {
        errorDescription = 'Knee position should be bent forward';
        setErrorMessage(errorDescription);
        recordError(errorType, params.SWING_POINT.KNEE, errorDescription, swingCurrentPosition);
    }
    
}

function checkTopSwing(keypoints) {
    if (swingCurrentPosition != params.SWING_POSITION.TOP) {
        return;
    }

    const rShoulder = keypoints[params.KEYPOINTS.RIGHT_SHOULDER];
    const lShoulder = keypoints[params.KEYPOINTS.LEFT_SHOULDER];
    const headMiddleY = keypoints[params.KEYPOINTS.NOSE].y;
    const shoulderMiddlePointY = (lShoulder.y + rShoulder.y) / 2;

    const lElbow = keypoints[params.KEYPOINTS.LEFT_ELBOW];
    const rElbow = keypoints[params.KEYPOINTS.RIGHT_ELBOW];
    const lHand = keypoints[params.KEYPOINTS.LEFT_WRIST];
    const rHand = keypoints[params.KEYPOINTS.RIGHT_WRIST];

    if (shoulderMiddlePointY < (headMiddleY - toleranceNormal))
    {
        errorType = "Shoulder";
        errorDescription = 'Shoulder should be below chin.';
        setErrorMessage(errorDescription);
        recordError(errorType, params.SWING_POINT.SHOULDER, errorDescription, swingCurrentPosition);
    }

    const angleArmL = mathUtils.getAngleBetweenThreePoints(lElbow, lHand, lShoulder);
    const angleArmR = mathUtils.getAngleBetweenThreePoints(rElbow, rHand, rShoulder);
    const leftCalculation = Math.abs(angleArmL);
    const rightCalculation = Math.abs(angleArmR);
    const expectedFrameGapForArmError = 3;
    const toleranceArmConstant = 3.37;
    const scoringLeft = lElbow.score > thresholdPoint && lHand.score > thresholdPoint;
    const scoringRight = rElbow.score > thresholdPoint && rHand.score > thresholdPoint;

    const verifyAngleForArm = leftCalculation && rightCalculation;
    if (!verifyAngleForArm) { return;}

    if ((leftCalculation >= toleranceArmConstant && scoringLeft) || (rightCalculation >= toleranceArmConstant && scoringRight))
    {
        errorArmTopCounter++;
    }

    if (errorArmTopCounter == expectedFrameGapForArmError)
    {
        errorArmTopCounter = 0;
        errorType = "Arm";
        errorDescription = 'Swing arm extended.';
        recordError(errorType, params.SWING_POINT.ARM, errorDescription, swingCurrentPosition);
    }
}

module.exports = errorsAnalysis;